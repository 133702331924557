async function fetchContent(url) {
  try {
    let response = await fetch(url)
    if (!response.ok) {
      throw new Error('Something went wrong!')
    }
    return await response.json()
  } catch (error) {
    console.error(error)
  }
}

window.onload = () => {
  var anchortags = document.querySelectorAll('a[href^="#"]');
  anchortags.forEach(function (anchor) {
    anchor.addEventListener('click', function (evt) {
      evt.preventDefault();
      var target = evt.target.attributes.href.value;

      // Scroll to a certain element
      document.querySelector(target).scrollIntoView({
        behavior: 'smooth'
      });
    });
  });

  document.querySelectorAll('[data-content-url]').forEach(item => {
    if (item.dataset.contentUrl) {
      const pagePromise = fetchContent(`https://shapshap.org/${item.dataset.contentUrl}`)

      pagePromise.then(res => {
        const heading = item.querySelector('.section-heading')
        const content = item.querySelector('.content')

        heading.textContent = res.title.rendered
        content.innerHTML = res.content.rendered

      })

    }
  })

  if (document.querySelector('#projects')) {
    const pagePromise = fetchContent(`https://shapshap.org/wp-json/wp/v2/projects?acf_format=standard`)
    const projectsContainer = document.querySelector('#projects')
    pagePromise.then(res => {

      res.forEach(project => {
        const article = document.createElement('div');
        article.classList.add('section')

        const projectHeader = document.createElement('h2')
        projectHeader.classList.add('section-heading')

        projectHeader.innerText = project.title.rendered

        article.insertAdjacentElement('beforeend', projectHeader)

        const projectList = document.createElement('ul')
        projectList.classList.add('projects')

        let listItems = ''

        const projects = project.acf.project.reverse();

        projects.forEach(item => {
          let event_name = ''

          if (item.event_name.length)
            event_name = item.event_name.replaceAll('~', '<br>')

          let title = item.title.replaceAll('~', '<br>')
  
          listItems += `<li>
          <figure class="project">
            <span class="year">${item.year}</span>
            <img src="${item.poster.url}" alt="${item.title.replace('/~/g', '<br>')}" class="img-fluid">
            <figcaption>
              ${event_name.length ? `<p>${event_name}</p>` : ''}
              <p>${title}</p>
            </figcaption>
            <span class="category">Projects</span>
            <div class="overlay"></div>
          </figure>
        </li>`

        })

        projectList.innerHTML = listItems

        article.insertAdjacentElement('beforeend', projectList)

        projectsContainer.insertAdjacentElement('beforeend', article)

      })

    })
  }
}
